import {
    filterExperiencesBy,
    filterExperiencesByInventory,
    removeExperiencesInventoryFilter
} from './experiences';
import { browserHistory } from 'react-router';

const actions: any = {
    ADD_TAG: 'tags/ADD_TAG',
    REMOVE_TAG: 'tags/REMOVE_TAG',
    RESET_TAGS: 'tags/RESET_TAGS',
    SELECT_TAG: 'tags/SELECT_TAG'
};

export const seedTags = (tags: any[]): any => {
    return (dispatch) => {
        tags.forEach((tag: any) => {
            dispatch(addTag(tag, false));
        });
    };
};

export const resetTags = (): any => {
    return { type: actions.RESET_TAGS };
};

export const addTag = (tag: any, userInvoked: boolean = true): any => {
    return (dispatch, getStore) => {
        const {
            tags,
            experiencesList: { filters }
        } = getStore();
        const [type, value] = tag.id.split(':');
        const nextPage: number = userInvoked ? 1 : filters.page;

        let freshExperienceModerationFilters: string[];
        let currBatchTag: any;
        let currInventoryTag: any;
        let inventoryValueSplit: any[];

        if (type === 'id') {
            dispatch(filterExperiencesBy({ experience_id: value, page: nextPage }));
        }

        if (type === 'moderation') {
            freshExperienceModerationFilters = [...filters.status, value];
            dispatch(
                filterExperiencesBy({ status: freshExperienceModerationFilters, page: nextPage })
            );
        }

        if (type === 'batch') {
            currBatchTag = tags.find((t: any) => t.id.includes('batch'));

            if (currBatchTag) {
                dispatch(removeTag(tags.indexOf(currBatchTag), false));
            }

            dispatch(filterExperiencesBy({ batch_id: value, page: nextPage }));
        }

        if (type === 'inventory_item') {
            inventoryValueSplit = value.split(' | ');

            currInventoryTag = tags.find((t: any) => t.id.includes('inventory_item'));

            if (currInventoryTag) {
                dispatch(removeTag(tags.indexOf(currInventoryTag), false));
            }

            dispatch(filterExperiencesByInventory(inventoryValueSplit[0], inventoryValueSplit[1]));
        }

        dispatch({ type: actions.ADD_TAG, tag });
    };
};

export const removeTag = (
    tagIndex: number,
    userInvoked: boolean = true,
    filterDataSet: boolean = false
): any => {
    return (dispatch, getStore) => {
        const {
            tags,
            experiencesList: { filters },
            story: {
                data: { id: storyId }
            }
        } = getStore();

        let freshModerationFilters: string[];
        if (tags.length > 0) {
            const tag: any = tags[tagIndex];
            const [type, value] = tag.id.split(':');
            const inventoryValueSplit = value.split(' | ');

            if (type === 'id') {
                dispatch(filterExperiencesBy({ experience_id: '', page: 1 }));
            }

            if (type === 'moderation') {
                freshModerationFilters = filters.status.filter((s) => s !== value);
                dispatch(filterExperiencesBy({ status: freshModerationFilters, page: 1 }));
            }

            if (type === 'batch' && userInvoked) {
                const orgId = new URLSearchParams(window.location.search).get('organization_id');
                browserHistory.replace(`/${storyId}?organization_id=${orgId}`);
                dispatch(filterExperiencesBy({ batch_id: '', page: 1 }));
                dispatch({ type: 'batches/SELECT', selectedBatch: {} });
            }

            if (type === 'inventory_item') {
                dispatch(removeExperiencesInventoryFilter(inventoryValueSplit[0]));
            }
        }

        dispatch({ type: actions.REMOVE_TAG, tagIndex });
    };
};

export const selectTag = (tag: any, userInvoked: boolean = true): any => {
    return (dispatch, getStore) => {
        const {
            experiencesList: { filters }
        } = getStore();
        const [type, value] = tag.id.split(':');
        const nextPage: number = userInvoked ? 1 : filters.page;

        if (type === 'moderation') {
            const filterObj = { status: [value], rendered_only: 0, page: nextPage };
            dispatch(filterExperiencesBy(filterObj));
        }

        dispatch({ type: actions.SELECT_TAG, tag });
    };
};

export default actions;
