import { api } from '../../constants/app';
import { resumePollingRenderStatus } from './batch-jobs';
import { setActiveTab } from './active-tab';
import { removeTag } from './tags';
import { BATCHES_COPY } from '../../constants/copy';

const actions: any = {
    SET_LOADING: 'batches/SET_LOADING',
    FILTER: 'batches/FILTER',
    CLEAR_FILTERS: 'batches/CLEAR_FILTERS',
    SELECT: 'batches/SELECT',
    SEED_ID: 'batches/SEED_ID',
    UPDATE_NAME: 'batches/UPDATE_NAME',
    FORCE_RENDER_STATUS: 'batches/FORCE_RENDER_STATUS',
    FORCE_ROWS_RENDERED: 'batches/FORCE_ROWS_RENDERED',
    UPDATE_TOTAL_ROWS: 'batches/UPDATE_TOTAL_ROWS',
    GET: 'batches/GET',
    DELETE: 'batches/DELETE',
    ERROR: 'batches/ERROR',
    SET_ERROR_MODAL: 'batches/SET_ERROR_MODAL'
};

export const setBatchesLoading = (toggle: boolean): any => {
    return { type: actions.SET_LOADING, toggle };
};

export const filterBatchesBy = (filters: any): any => {
    return { type: actions.FILTER, filters };
};

export const removeBatchesFilters = (): any => {
    return { type: actions.CLEAR_FILTERS };
};

export const selectBatch = (selectedBatch: any): any => {
    return { type: actions.SELECT, selectedBatch };
};

export const seedBatchId = (batchId: string): any => {
    return { type: actions.SEED_ID, batchId };
};

export const forcedRowRendered = (batchId: string, rows: number): any => {
    return { type: actions.FORCE_ROWS_RENDERED, batchId, rowsRendered: rows };
};

export const forceRenderStatus = (batchId: string, status: string): any => {
    return { type: actions.FORCE_RENDER_STATUS, batchId, forcedRenderStatus: status };
};

export const updateTotalRows = (batchId: string, total: number): any => {
    return { type: actions.UPDATE_TOTAL_ROWS, batchId, total };
};

export const getBatches = (): any => {
    return (dispatch, getStore) => {
        return new Promise<void>((resolve, reject) => {
            const {
                story,
                batchesList: { filters, selected },
                batchJobs: { renders }
            } = getStore();
            const { rendering, failed } = BATCHES_COPY.progress;
            let activeBatch: any;

            dispatch(setBatchesLoading(true));
            api.getBatches(story.data.id, filters)
                .then((batches: any) => {
                    if (Object.keys(selected).length === 1) {
                        activeBatch = batches.batches.find((b: any) => b.id === selected.id);

                        if (typeof activeBatch === 'object') {
                            dispatch(selectBatch(activeBatch));
                        }
                    }

                    batches.batches.forEach((batch: any) => {
                        if (batch.status === rendering && !renders.hasOwnProperty(batch.id)) {
                            dispatch(resumePollingRenderStatus(batch.id));
                        }

                        if (batch.status === failed && batch.id) {
                            api.getBatchError(batch.id)
                                .then((error) => {
                                    dispatch({ type: actions.ERROR, error });
                                })
                                .catch((e) => console.error(e));
                        }
                    });

                    dispatch({ type: actions.GET, batches });
                    dispatch(setBatchesLoading(false));
                    resolve();
                })
                .catch((e: Error) => {
                    dispatch(setBatchesLoading(false));
                    reject(e);
                });
        });
    };
};

export const createFreshBatch = (batchName: string): any => {
    return (dispatch, getStore) => {
        if (batchName.length > 0) {
            return new Promise<void>((resolve, reject) => {
                const { story } = getStore();

                api.createFreshBatch(story.data.id, batchName)
                    .then((batch: any) => {
                        dispatch(getBatches());
                        dispatch(selectBatch(batch));
                        resolve(batch);
                    })
                    .catch((e: Error) => {
                        reject(e);
                    });
            });
        }
    };
};

export const editBatchName = (batchId: string, name: string): any => {
    return (dispatch) => {
        return new Promise<void>((resolve, reject) => {
            api.updateBatchName(batchId, name)
                .then(() => {
                    dispatch({ type: actions.UPDATE_NAME, batchId, name });
                    resolve();
                })
                .catch((e) => {
                    console.error('Failed to update batch name', e);
                    reject();
                });
        });
    };
};

export const deleteBatch = (batchId: string): any => {
    return (dispatch, getStore) => {
        const {
            tags,
            batchesList: { selected }
        } = getStore();

        if (batchId === selected.id) {
            dispatch(setActiveTab(0));
            dispatch(removeTag(tags.findIndex((t) => t.id.split(':')[1] === batchId)));
        }

        return new Promise<void>((resolve, reject) => {
            api.deleteBatch(batchId)
                .then(() => {
                    dispatch({ type: actions.DELETE });
                    dispatch(getBatches());
                    resolve();
                })
                .catch((e: Error) => {
                    reject(e);
                });
        });
    };
};

export const setErrorModal = (toggle: boolean, id: any): any => {
    return { type: actions.SET_ERROR_MODAL, toggle, id };
};

export default actions;
