import * as React from 'react';
import ColumnMaskMenu from './experiences/ColumnMaskMenu';
import ExperiencesTable from './experiences/ExperiencesTable';
import DataSetTable from './data-set/DataSetTable';
import {
    Section,
    Button,
    ButtonMenu,
    ImposiumDropdown,
    ShortcutMenu
} from '@imposium-hub/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons/faKeyboard';
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons/faFolderPlus';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { EXPERIENCES_COPY, DATA_SET_COPY } from '../constants/copy';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getExperiences, exportExperiences, filterList } from '../redux/actions/experiences';
import { toggleBatchesTable } from '../redux/actions/panes';
import { getBatch, addColumn, insertNewRow } from '../redux/actions/active-batch';
import { setActiveTab } from '../redux/actions/active-tab';

interface ITabbedSectionProps {
    panes: any;
    activeTab: number;
    activeBatch: any;
    batchesList: any;
    hasBatchAccess: boolean;
    experiencesList: any;
    getExperiences: () => any;
    toggleBatchesTable: (toggle: boolean) => any;
    getBatch: () => any;
    addColumn: () => any;
    setActiveTab: (tabName: string) => any;
    exportExperiences: () => void;
    insertNewRow: (rowIndex: number) => any;
    filterList: () => void;
}

interface ITabbedSectionState {
    showShortcutMenu: boolean;
    showColumnMasksMenu: boolean;
}

class TabbedSection extends React.Component<ITabbedSectionProps, ITabbedSectionState> {
    private readonly shortcutsToggleRef: any;

    private readonly columnMasksToggleRef: any;

    constructor(p: ITabbedSectionProps) {
        super(p);

        this.state = {
            showShortcutMenu: false,
            showColumnMasksMenu: false
        };

        this.columnMasksToggleRef = React.createRef();
        this.shortcutsToggleRef = React.createRef();
    }

    private getTabs = () => {
        const {
            batchesList: {
                selected,
                selected: { name }
            }
        } = this.props;

        if (Object.keys(selected).length >= 1 && selected.id && selected.id !== 'undefined') {
            return [
                EXPERIENCES_COPY.sectionTitle,
                `${DATA_SET_COPY.sectionTitle}: ${name || '...'}`
            ];
        }

        return [EXPERIENCES_COPY.sectionTitle];
    };

    public render() {
        const {
            hasBatchAccess,
            panes: { showBatchesTable },
            activeBatch,
            activeTab
        } = this.props;
        const tabOpts = this.getTabs();
        const experiencesActive: boolean = activeTab === 0;
        const actionButtons: JSX.Element[] = [];
        if (experiencesActive) {
            actionButtons.push(
                <Button
                    key='download'
                    style='subtle'
                    size='small'
                    tooltip={EXPERIENCES_COPY.tooltips.downloadCSV}
                    onClick={() => this.props.exportExperiences()}>
                    <FontAwesomeIcon icon={faDownload} />
                </Button>,
                <Button
                    key='column-masks'
                    style='subtle'
                    size='small'
                    tooltip={EXPERIENCES_COPY.tooltips.tableColumns}
                    buttonRef={this.columnMasksToggleRef}
                    onClick={() =>
                        this.setState({ showColumnMasksMenu: !this.state.showColumnMasksMenu })
                    }>
                    <FontAwesomeIcon icon={faColumns} />
                </Button>,
                <Button
                    key='refresh'
                    style='subtle'
                    size='small'
                    tooltip={EXPERIENCES_COPY.tooltips.tableRefresh}
                    onClick={this.props.getExperiences}>
                    <FontAwesomeIcon icon={faRedo} />
                </Button>,
                <Button
                    key='experiences-shortcuts'
                    style='subtle'
                    size='small'
                    tooltip={EXPERIENCES_COPY.tooltips.keyboardShortcuts}
                    buttonRef={this.shortcutsToggleRef}
                    onClick={() =>
                        this.setState({ showShortcutMenu: !this.state.showShortcutMenu })
                    }>
                    <FontAwesomeIcon icon={faKeyboard} />
                </Button>
            );
        } else {
            actionButtons.push(
                <ButtonMenu
                    key='edit-menu'
                    button={
                        <Button
                            style='subtle'
                            size='small'
                            tooltip={'Insert'}>
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    }
                    items={[
                        <div
                            key='add-column'
                            className='button-menu-item'
                            onClick={this.props.addColumn}>
                            Add Column
                        </div>,
                        Array.isArray(activeBatch.data.columns) &&
                        activeBatch.data.columns.length > 0 ? (
                            <div
                                key='add-row'
                                className='button-menu-item'
                                onClick={() => this.props.insertNewRow(0)}>
                                Add Row
                            </div>
                        ) : (
                            <React.Fragment key='stub'></React.Fragment>
                        )
                    ]}
                    position='left'
                />,
                <Button
                    key='refresh'
                    style='subtle'
                    size='small'
                    tooltip={DATA_SET_COPY.tooltips.tableRefresh}
                    onClick={this.props.getBatch}>
                    <FontAwesomeIcon icon={faRedo} />
                </Button>
            );
        }

        if (hasBatchAccess) {
            actionButtons.push(
                <Button
                    key='toggle-expanded'
                    style='subtle'
                    size='small'
                    tooltip={
                        !showBatchesTable
                            ? EXPERIENCES_COPY.tooltips.tableExpanded
                            : EXPERIENCES_COPY.tooltips.tableCollapsed
                    }
                    onClick={() => this.props.toggleBatchesTable(!showBatchesTable)}>
                    {!showBatchesTable ? (
                        <FontAwesomeIcon
                            icon={faFolderPlus}
                            color='#2d8ceb'
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faChevronUp}
                            color='#2d8ceb'
                        />
                    )}
                </Button>
            );
        }

        return (
            <Section
                className='experiences-section'
                isLoading={this.props.activeBatch.updating}
                tabs={tabOpts}
                activeTabName={tabOpts[activeTab]}
                selectTab={(_, i) => this.props.setActiveTab(i)}
                buttons={actionButtons}>
                {experiencesActive && <ExperiencesTable />}
                {!experiencesActive && <DataSetTable />}

                <ImposiumDropdown
                    position='bottomright'
                    show={this.state.showColumnMasksMenu}
                    toggleRef={this.columnMasksToggleRef}
                    onOutsideClick={() => this.setState({ showColumnMasksMenu: false })}>
                    <ColumnMaskMenu />
                </ImposiumDropdown>

                <ImposiumDropdown
                    position='bottomright'
                    show={this.state.showShortcutMenu}
                    toggleRef={this.shortcutsToggleRef}
                    onOutsideClick={() => this.setState({ showShortcutMenu: false })}>
                    <ShortcutMenu shortcutCopyMap={EXPERIENCES_COPY.shortcuts} />
                </ImposiumDropdown>
            </Section>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getExperiences,
            exportExperiences,
            toggleBatchesTable,
            getBatch,
            addColumn,
            setActiveTab,
            insertNewRow,
            filterList
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return {
        panes: state.panes,
        activeTab: state.activeTab,
        activeBatch: state.activeBatch,
        batchesList: state.batchesList,
        experiencesList: state.experiencesList
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabbedSection);
